import React, { useState, Fragment, useEffect } from 'react';
// import ReactDatatable from '@mkikets/react-datatable';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { baseURL, CONFIG, errorToast, successToast, ToastTable, USER } from '../../configs/exports';
import { Link, Redirect } from 'react-router-dom';
import Loader from "react-loader-spinner";
import "../../_assets/css/csv.css";
import ProductItems from '../../shared/components/catalog/ProductItems';
import * as moment from 'moment';
import {
    Col, Row,
    Card, CardBody, Table, Button, TabContent, TabPane,
} from 'reactstrap';

import Modal from 'react-modal';
import { Form } from 'react-bootstrap';
import { MDBCloseIcon } from "mdbreact";
import Avatar from 'react-avatar';
import DatePicker from "react-datepicker";
import { Portal } from "react-overlays";


const CustomerDetails = (state) => {

    // alert(JSON.stringify(state))

    const [isLoading, setLoad] = useState(true)
    const [datatotal, setDataTotal] = useState(0)
    const [queryString, setQueryString] = useState(`&page_number=1&page_size=100&sort_order=false`)

    // eslint-disable-next-line
    const [customer_data, setCustomer] = useState({})
    // eslint-disable-next-line


    const [open, IsOpen] = useState(false);


    const [open_limit, IsOpenLimit] = useState(false);

    const [customer_document, setCustomerDocuments] = useState({})

    const [state_value, setState] = useState({
        amount: ''
    })

    const [start_date, setStartDate] = useState(new Date());

    // const [end_date, setEndDate] = useState(new Date());

    const [description, setDescription] = useState('')

    const [document_status, setDocumentStatus] = useState(false)

    const [loans, setLoan] = useState([])


    const [home, setHome] = useState(false)




    const [customer_statement, setStatement] = useState(false)

    const [statement_reference, setReference] = useState('')






    // const [avatar, setAvatar] = useState("") // set profile picture


    // alert(state.location.state.farmer)

    useEffect(() => {
        setCustomer(state.location.state.farmer)

    }, [queryString]);// eslint-disable-line react-hooks/exhaustive-deps

    const locale = 'en-US';
    const formatCurrency = amount => {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: "ABS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount).replaceAll('ABS', "");
    };

    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: false,
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        button: {
            csv: true,
            excel: true,
            print: true
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Tag ID..",
            no_data_text: "There was no record found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }


    //livestock list

    const columns_livestock = [
        {
            key: "f_amount_limit",
            TrOnlyClassName: 'cell',
            text: "Limit",
            className: "tsc",
            align: "left"
        },
        {
            key: "f_amount",
            TrOnlyClassName: 'cell',
            text: "Amount Issued",
            className: "tsc",
            align: "left"
        },
        {
            key: "f_amount_interest",
            TrOnlyClassName: 'cell',
            text: "Interest",
            className: "tsc",
            align: "left"
        },

        {
            key: "action",
            text: "Days",
            TrOnlyClassName: 'cell',
            className: "cell",
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >

                        {record.day < 31 ?
                            <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                {record.day} days Old
                            </span>
                            :
                            record.day > 30 ?
                                <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                    Overdue by {record.day - 30}
                                </span>
                                : null}
                    </Fragment >
                );
            }
        },


        {
            key: "action",
            text: "Status",
            TrOnlyClassName: 'cell',
            className: "cell",
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >

                        {record.status === 1 ?
                            <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                Applied
                            </span>
                            :
                            record.status === 2 ?
                                <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                    Paid
                                </span>

                                :
                                <>
                                    {
                                        record.status === 3 ?
                                            <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                                Defaulted
                                            </span>
                                            : null
                                    }
                                </>
                        }
                    </Fragment >
                );
            }
        },
        {
            key: "enddates",
            TrOnlyClassName: 'tsc',
            text: "End Date",
            className: "cell",
            align: "left"
        },
        {
            key: "dates",
            TrOnlyClassName: 'tsc',
            text: "Date Created",
            className: "cell",
            align: "left"
        },

        {
            key: "action",
            text: "Options",
            TrOnlyClassName: 'cell',
            className: "cell",
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >

                        <button className="btn btn-success btn-sm"
                            title="View pics Details"
                            style={
                                { marginRight: '10px' }}
                            onClick={() => { customerDetails(record) }} >
                            Statement
                        </button>

                    </Fragment>
                );
            }
        }
    ];






    // handles paginations
    const tableChangeHandler = data => {
        const b = Object.assign({}, data, { filter_value: state.location.state.farmer.id });
        // alert("Something")
        let queryString;
        if (b.filter_value === null || b.filter_value === '') {
            alert(JSON.stringify({ b }))

            queryString = Object.keys(b).map((key) => {
                if (key === "sort_order" && b[key]) {
                    return encodeURIComponent("sort_order") + '=' + encodeURIComponent(b[key].order) + '&' +
                        encodeURIComponent("sort_column") + '=' + encodeURIComponent(b[key].column)
                } else {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(b[key])
                }
            }).join('&');
        }
        else {
            queryString = Object.keys(data).map((key) => {
                if (key === "sort_order" && data[key]) {
                    return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column")
                        + '=' + encodeURIComponent(data[key].column)
                } else {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                }
            }).join('&');
            // delete data.filter_value; // or use => delete test['blue'];
            // alert(JSON.stringify(data))
        }
        setQueryString(queryString)
    }

    const backHome = () => {
        setHome(true)
    }


    const customerDetails = (record) => {
        setStatement(true)
        setReference(record.reference)
    }




    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const closeModal = () => {
        IsOpen(false)
    }
    const openModal = () => {
        IsOpen(true)
    }


    const closeModalLimit = (e) => {
        IsOpenLimit(false)
    }
    const openModalLimit = () => {
        IsOpenLimit(true)
    }

    const onSubmitLimit = (e) => {
        e.preventDefault();
        setLoad(true)
        let formData = {
            amount: state_value.amount,
            customer_id: state.location.state.farmer.id,
            description: description,
            name: customer_data.name,
            msisdn: customer_data.msisdn,

        }
        axios.post(`${baseURL}update_limit`, formData, CONFIG).then(res => {
            setLoad(false)
            if (res.data.success) {
                successToast(res.data.message)
                setState({
                    ...state,
                    amount: ''
                })
                setTimeout(() => {
                    setQueryString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                    window.location.reload()

                }, 3000)
                closeModal(false)
            } else {
                errorToast(res.data.message)
                closeModal(false)
                setQueryString(require("randomstring").generate({
                    length: 1,
                    charset: 'alphanumeric',
                    capitalization: 'lowercase'
                }))
            }
        }).catch(err => {
            setLoad(false)
            closeModal(false)
            errorToast(err.message)
        })

    }

    const onSubmit = (e) => {
        e.preventDefault();
        setLoad(true)
        let formData = {
            amount: state_value.amount,
            customer_id: state.location.state.farmer.id,
            end_date: moment.utc(start_date).format('YYYY-MM-DD') + " 23:59:59",
            agent_id: USER.user_id
        }

        axios.put(`${baseURL}customer_documents`, formData, CONFIG).then(res => {
            setLoad(false)
            if (res.data.success) {
                successToast(res.data.message)
                setState({
                    ...state,
                    amount: ''
                })
                setTimeout(() => {
                    setQueryString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                    window.location.reload()

                }, 3000)

                closeModal(false)
            } else {
                errorToast(res.data.message)
                closeModal(false)
                setQueryString(require("randomstring").generate({
                    length: 1,
                    charset: 'alphanumeric',
                    capitalization: 'lowercase'
                }))


            }
        }).catch(err => {

            setLoad(false)
            closeModal(false)
            errorToast(err.message)
        })

    }




    const onSubmitReupload = (e) => {
        e.preventDefault();
        setLoad(true)
        let formData = {
            customer_id: state.location.state.farmer.id,
            name: state.location.state.farmer.name,
            msisdn: customer_data.msisdn
        }

        axios.post(`${baseURL}customer_document_reupload`, formData, CONFIG).then(res => {
            setLoad(false)
            if (res.data.success) {
                successToast(res.data.message)
                setState({
                    ...state,
                    amount: ''
                })
                setTimeout(() => {
                    setQueryString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                    window.location.reload()

                }, 3000)

                closeModal(false)
            } else {
                errorToast(res.data.message)
                closeModal(false)
                setQueryString(require("randomstring").generate({
                    length: 1,
                    charset: 'alphanumeric',
                    capitalization: 'lowercase'
                }))
            }
        }).catch(err => {

            setLoad(false)
            closeModal(false)
            errorToast(err.message)
        })
    }


    const CalendarContainer = ({ children }) => {
        const el = document.getElementById("calendar-portal");
        return <Portal container={el}>{children}</Portal>;
    };
    //UI returned
    return (
        <div style={{ marginTop: "-20px" }} >
            {ToastTable()}



            {customer_statement ?
                <Redirect
                    to={{
                        pathname: '/customer_statement',
                        state: {
                            reference: {
                                reference: statement_reference,
                                id: state.location.state.farmer.id,
                                name: state.location.state.farmer.name,
                                id_no: state.location.state.farmer.id_no
                            }
                        }
                    }}
                /> : null}

            {
                home === true ?
                    <>
                        <Redirect to="customers" />
                    </> :

                    <Card>
                        <CardBody className="profile__card">
                            <br />
                            <div className="row col-12">
                                <div className="col-8">

                                    <div className="profile__information">
                                        <div className="profile__avatar">
                                            <Avatar name={state.location.state.farmer.username} />
                                        </div>


                                        <div className="">
                                            <br />
                                            <p className="profile__name" style={{ marginBottom: "10px" }}>Username:  <b style={{ color: 'green' }}>{customer_data.username}</b> </p>
                                            <p className="profile__name" style={{ marginBottom: "10px" }}>Phone number:  <b style={{ color: 'green' }}>{customer_data.msisdn}</b></p>

                                            <p className="profile__name" style={{ marginBottom: "10px" }}>Email:  <b style={{ color: 'green' }}>{customer_data.email}</b></p>
                                            <p className="profile__name" style={{ marginBottom: "10px" }}>balance: <b style={{ color: 'green' }}> {formatCurrency(customer_data.balance_satoshis)}</b></p>
                                            <p className="profile__name" style={{ marginBottom: "10px" }}>Date Joined: <b style={{ color: 'green' }}>  {moment(customer_data.created).format('YYYY-MM-DD HH:mm:ss')}</b></p>


                                            <p className="profile__name" style={{ marginBottom: "10px" }}>

                                                <br />
                                                <Row>


                                                    <Link to={{
                                                        pathname: '/customer_deposits',
                                                        state: {
                                                            farmer: state.location.state.farmer
                                                        }


                                                    }} style={{ marginBottom: "20px" }}>
                                                        <Button className="pull-left btn-sm btn btn-primary" style={{ color: "white" }}>
                                                            Customer Deposits
                                                        </Button>
                                                    </Link >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


                                                    <Link to={{
                                                        pathname: '/customer_withdrawal',
                                                        state: {
                                                            farmer: state.location.state.farmer
                                                        }

                                                    }} style={{ marginBottom: "20px" }}>
                                                        <Button className="pull-left btn-sm btn btn-primary" style={{ color: "white" }}>
                                                            Customer Withdrawals
                                                        </Button>
                                                    </Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                                    <Link to={{
                                                        pathname: '/customer_statement',
                                                        state: {
                                                            farmer: state.location.state.farmer
                                                        }

                                                    }} style={{ marginBottom: "20px" }}>
                                                        <Button className="pull-left btn-sm btn btn-primary" style={{ color: "white" }}>
                                                            Customer Statement
                                                        </Button>
                                                    </Link>

                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                                    <Link to={{
                                                        pathname: '/messages',
                                                        state: {
                                                            farmer: state.location.state.farmer
                                                        }

                                                    }} style={{ marginBottom: "20px" }}>
                                                        <Button className="pull-left btn-sm btn btn-primary" style={{ color: "white" }}>
                                                            All Messages
                                                        </Button>

                                                    </Link>
                                                    <Button className="pull-left btn-sm btn btn-danger" style={{ color: "white" }}>
                                                            BlackList?
                                                        </Button>

                                                </Row>


                                            </p>
                                        </div>

                                    </div>

                                </div>

                                <div className="col-4">

                                    <>
                                        <Button className="pull-right btn-sm" onClick={backHome} outline color="primary">
                                            Back to Customers
                                        </Button>
                                    </>
                                </div>
                            </div>
                        </CardBody >

                    </Card >
            }



        </div >
    );
}

export default CustomerDetails;
