import React, { useState, useEffect, Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { Button, Card, CardBody, Table } from 'reactstrap';
import { baseURL, CONFIG, errorToast, formatCurrency, successToast, ToastTable, useStyles } from '../../configs/exports';
import * as moment from 'moment';
import { Autorenew } from "@material-ui/icons";
import clsx from "clsx";
import { Link } from 'react-router-dom';


//farmer hooks

const CustomerDepositAll = () => {

    const [farmer, setFarmer] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [datatotal, setDataTotal] = useState()
    const [queryString, setQueryString] = useState("")

    // alert(state.location.state.farmer_id)
    const [spin, setSpin] = useState(false);
    const refreshCanvas = () => {
        setSpin(true);
        setTimeout(() => {
            setSpin(false);
        }, 1000);
    };
    const classes = useStyles();


    useEffect(() => {
        setLoad(true)

        let url = baseURL + `customer_deposit_all?${queryString}`;
        // alert(JSON.stringify(state.location.state.farmer))
        axios.all([
            axios.get(url, CONFIG)
        ]).then(axios.spread((farmerResponse) => {
            let data = [];
            if (farmerResponse.data.data.length > 0) {

                /**
               * @description If list is
               */
                let params = new URLSearchParams(queryString)
                let id = parseInt(params.get("page_number"))

                let p = 0;
                if (id > 1) {
                    p = (((id - 1) * parseInt(params.get("page_size"))))
                }

                setDataTotal(farmerResponse.data.data.length)
                setLoad(false)
                for (let i = 0; i < farmerResponse.data.data.length; i++) {
                    p++;
                    let unrow = { row: p }
                    let m_debit = { m_debit: formatCurrency(farmerResponse.data.data[i].amount) };

                    let m_balance = { m_balance: formatCurrency(farmerResponse.data.data[i].balance) };

                    let date = { dates: (moment.utc(farmerResponse.data.data[i].created).format('DD/MM/YYYY HH:mm:ss')) };
                    data.push(Object.assign(date, unrow, m_balance, m_debit, farmerResponse.data.data[i]));
                    setLoad(false)
                }
                setDataTotal(farmerResponse.data.total)
                setFarmer(data)
            } else {
                setLoad(false)
                setFarmer(data)
            }
        }))

    }, [queryString]);// eslint-disable-line react-hooks/exhaustive-deps

    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        button: {
            csv: true
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search..",
            no_data_text: "There was no record found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }

    const columns = [
        {
            key: "row",
            TrOnlyClassName: 'cell',
            text: "#",
            className: "tsc",
            align: "left"
        },
        {
            key: "m_debit",
            TrOnlyClassName: 'cell',
            text: "Amount",
            className: "tsc",
            align: "left"
        },

        {
            key: "bitcoin_deposit_txid",
            TrOnlyClassName: 'cell',
            text: "Transaction ID",
            className: "tsc",
            align: "left"
        },

        {
            key: "m_balance",
            TrOnlyClassName: 'cell',
            text: "Balance",
            className: "tsc",
            align: "left"
        },


        {
            key: "action",
            text: "Status",
            TrOnlyClassName: 'cell',
            className: "cell",
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >

                        {record.status === 'success' ?
                            <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                Success
                            </span>
                            :
                            record.status === 'failed' ?
                                <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                    Failed
                                </span>

                                :
                                <>
                                    {
                                        record.status === 'initiated' ?
                                            <span class="badge-warning" style={{ borderRadius: "5px", padding: "2px" }}>
                                                Initiated
                                            </span>
                                            : null
                                    }
                                </>
                        }
                    </Fragment >
                );
            }
        },
        {
            key: "email",
            TrOnlyClassName: 'cell',
            text: "Email",
            className: "tsc",
            align: "left"
        },

        {
            key: "description",
            TrOnlyClassName: 'cell',
            text: "Description",
            className: "tsc",
            align: "left"
        },

        {
            key: "channel",
            TrOnlyClassName: 'cell',
            text: "Channel",
            className: "tsc",
            align: "left"
        },


        {
            key: "dates",
            TrOnlyClassName: 'cell', text: "Date",
            className: "tsc",
            align: "left"
        },
        // // {
        // //     key: "action",
        // //     text: "Options",
        // //     TrOnlyClassName: 'cell',
        // //     with: 100,
        // //     className: "tsc",
        // //     sortable: false,
        // //     cell: record => {
        // //         return (
        // //             <Fragment className="center" >
        // //                 {/* <Link to="/farmer_details" > */}
        // //                 {record.status === 'success' ?
        // //                     null :
        // //                     <button className="btn btn-success btn-sm"
        // //                         title="View More"
        // //                         style={
        // //                             { marginRight: '10px' }}
        // //                         onClick={() => { if (window.confirm(`Are you sure you want to reconcile this transaction ${record.bitcoin_deposit_txid}?`)) onSubmitReupload(record) }} >
        // //                         Reconcile
        // //                     </button>
        // //                 }

        // //                 {/* </Link> */}
        // //             </Fragment>
        // //         )
        // //     }
        // }
    ];

    const onSubmitReupload = (e) => {
        // e.preventDefault();
        setLoad(true)
        let formData = {
            transaction_id: e.bitcoin_deposit_txid,
            reference: e.bitcoin_deposit_txid,
            amount: e.amount,
            status: "success",
            name: ""
        }

        console.log(formData)

        axios.post(`https://play.kashout.ng/deposit`, formData, CONFIG).then(res => {
            setLoad(false)
            if (res.data.success) {
                successToast(res.data.message)
                setTimeout(() => {
                    setQueryString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                    window.location.reload()
                }, 3000)
            } else {
                errorToast(res.data.message)
                setLoad(false)
                setQueryString(require("randomstring").generate({
                    length: 1,
                    charset: 'alphanumeric',
                    capitalization: 'lowercase'
                }))


            }
        }).catch(err => {
            setLoad(false)

            errorToast(err.message)
        })

    }




    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');
        setQueryString(queryString)
    }



    return (
        <div style={{ marginTop: "-20px" }} >
            < >
                {ToastTable()}
                < Card >
                    <CardBody >
                        <Table responsive hover>
                            <div className="panel-body" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4> <b>Deposits</b></h4>
                                    </div>
                                   
                                </div>

                                <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}

                                    onClick={
                                        () => {
                                            refreshCanvas()
                                            setQueryString(require("randomstring").generate({
                                                length: 1,
                                                charset: 'alphanumeric',
                                                capitalization: 'lowercase'
                                            }))
                                        }}
                                >
                                    Refresh
                                    <Autorenew
                                        className={clsx({
                                            [classes.refresh]: true,
                                            spin: spin
                                        })}
                                        onClick={
                                            () => {
                                                refreshCanvas()
                                                setQueryString(require("randomstring").generate({
                                                    length: 1,
                                                    charset: 'alphanumeric',
                                                    capitalization: 'lowercase'
                                                }))
                                            }}
                                        spin={360}
                                    />

                                </button>

                                <br />
                                <ReactDatatable
                                    config={config}
                                    records={farmer}
                                    columns={columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={isLoading}
                                    total_record={datatotal}
                                    onChange={tableChangeHandler} />
                            </div>
                        </Table>
                    </CardBody>


                </Card>

            </>
        </div>
    )

}
export default CustomerDepositAll;