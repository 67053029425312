import React, { useState, useEffect, Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { Button, Card, Form, CardBody, Table } from 'reactstrap';
import { baseURL, CONFIG, errorToast, formatCurrency, successToast, ToastTable, useStyles } from '../../configs/exports';
import * as moment from 'moment';
import { Autorenew } from "@material-ui/icons";
import clsx from "clsx";
import { Link } from 'react-router-dom';
import { MDBCloseIcon } from "mdbreact";
import Modal from 'react-modal';


//farmer hooks

const Advertisers = () => {

    const [farmer, setFarmer] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [datatotal, setDataTotal] = useState()
    const [queryString, setQueryString] = useState("")
    const [open_add, IsOpenAdd] = useState(false);  // define open edit modal

    const [name, setName] = useState("");
    // alert(state.location.state.farmer_id)
    const [spin, setSpin] = useState(false);
    const refreshCanvas = () => {
        setSpin(true);
        setTimeout(() => {
            setSpin(false);
        }, 1000);
    };
    const classes = useStyles();


    useEffect(() => {
        setLoad(true)

        let url = baseURL + `advertiser?${queryString}`;
        // alert(JSON.stringify(state.location.state.farmer))
        axios.all([
            axios.get(url, CONFIG)
        ]).then(axios.spread((farmerResponse) => {
            let data = [];
            if (farmerResponse.data.data.length > 0) {

                /**
               * @description If list is
               */
                let params = new URLSearchParams(queryString)
                let id = parseInt(params.get("page_number"))

                let p = 0;
                if (id > 1) {
                    p = (((id - 1) * parseInt(params.get("page_size"))))
                }

                setDataTotal(farmerResponse.data.data.length)
                setLoad(false)
                for (let i = 0; i < farmerResponse.data.data.length; i++) {
                    p++;
                    let unrow = { row: p }

                    let lnk = `https://play.kashout.ng/register?ref=${farmerResponse.data.data[i].ad_reference}`
                    let mlink = { link:  lnk.replaceAll(" ", "")};

                    // let date = { dates: (moment.utc(farmerResponse.data.data[i].created).format('DD/MM/YYYY HH:mm:ss')) };
                    data.push(Object.assign(mlink, unrow, farmerResponse.data.data[i]));
                    setLoad(false)
                }
                setDataTotal(farmerResponse.data.total)
                setFarmer(data)
            } else {
                setLoad(false)
                setFarmer(data)
            }
        }))

    }, [queryString]);// eslint-disable-line react-hooks/exhaustive-deps

    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: false,
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        button: {
            csv: true
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search..",
            no_data_text: "There was no record found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }

    const columns = [
        {
            key: "row",
            TrOnlyClassName: 'cell',
            text: "#",
            className: "tsc",
            align: "left"
        },
        {
            key: "name",
            TrOnlyClassName: 'cell',
            text: "Name",
            className: "tsc",
            align: "left"
        },

        {
            key: "link",
            TrOnlyClassName: 'cell',
            text: "Link",
            className: "tsc",
            align: "left"
        },
        // {
        //     key: "dates",
        //     TrOnlyClassName: 'cell', text: "Date",
        //     className: "tsc",
        //     align: "left"
        // },
        {
            key: "action",
            text: "Options",
            TrOnlyClassName: 'cell',
            with: 100,
            className: "tsc",
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >
                        {/* <Link to="/farmer_details" > */}
                        <button className="btn btn-success btn-sm"
                            title="View More"
                            style={
                                { marginRight: '10px' }}
                            onClick={() => {
                                successToast('Copied!')
                                navigator.clipboard.writeText(record.link);
                            }} >
                            Copy to Clipboard
                        </button>
                    </Fragment>
                )
            }
        }
    ];

    const onSubmitReupload = (e) => {
        e.preventDefault();
        setLoad(true)
        let formData = {
            name: name
        }

        if (name === "") {
            errorToast("Please enter a name")
            setLoad(false)
            return
        }
        axios.post(`https://play.kashout.ng/add_advert`, formData, CONFIG).then(res => {
            setLoad(false)
            if (res.data.success) {
                successToast(res.data.message)
                setName("")
                setTimeout(() => {
                    setQueryString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                    window.location.reload()
                }, 3000)
            } else {
                errorToast(res.data.message)
                setLoad(false)
                setQueryString(require("randomstring").generate({
                    length: 1,
                    charset: 'alphanumeric',
                    capitalization: 'lowercase'
                }))


            }
        }).catch(err => {
            setLoad(false)

            errorToast(err.message)
        })

    }




    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');
        setQueryString(queryString)
    }

    const closeModalAdd = () => {
        IsOpenAdd(false)


    }

    const handleChangeUsername = (e) => {
        setName(e.target.value)
    }



    return (
        <div style={{ marginTop: "-20px" }} >
            < >
                {ToastTable()}
                < Card >


                    <Modal
                        isOpen={open_add}
                        onRequestClose={e => {
                            closeModalAdd(e);
                        }}

                        contentLabel="My dialog"
                        className="mymodal"
                        onAfterOpen={() => {
                            document.body.style.overflow = 'hidden';
                        }}
                        onAfterClose={() => {
                            document.body.removeAttribute('style');
                        }}
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                    >
                        <MDBCloseIcon onClick={closeModalAdd} />
                        <h4><b>Add Advert Form</b></h4>
                        <>
                            <Form className="form login-form" onSubmit={onSubmitReupload} >
                                <div className="col-md-10 offset-1">
                                    <div className="form-group">
                                        <div className="col-md-12">
                                            <label className="form-label">Advert Platform</label>
                                        </div>
                                        <div className="col-md-12">
                                            <input id="input" type="text" className="form-control"
                                                name="name" placeholder='Name'
                                                value={name} onChange={handleChangeUsername} />
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                </div>

                                <div className="account__btns col-8 offset-2" >
                                    <Button className="account__btn" type='submit' color="success"> {
                                        isLoading ? "Please wait..." : "Submit"
                                    } </Button>
                                </div>
                            </Form>
                        </>
                    </Modal>


                    <CardBody >
                        <Table responsive hover>
                            <div className="panel-body" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4> <b>Adverts</b></h4>
                                    </div>

                                </div>

                                <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}

                                    onClick={
                                        () => {
                                            refreshCanvas()
                                            setQueryString(require("randomstring").generate({
                                                length: 1,
                                                charset: 'alphanumeric',
                                                capitalization: 'lowercase'
                                            }))
                                        }}
                                >
                                    Refresh
                                    <Autorenew
                                        className={clsx({
                                            [classes.refresh]: true,
                                            spin: spin
                                        })}
                                        onClick={
                                            () => {
                                                refreshCanvas()
                                                setQueryString(require("randomstring").generate({
                                                    length: 1,
                                                    charset: 'alphanumeric',
                                                    capitalization: 'lowercase'
                                                }))
                                            }}
                                        spin={360}
                                    />

                                </button>

                                <div className=" float-right">
                                    <Button className="account__btn float-right" type='button' onClick={e => {
                                        IsOpenAdd(true)
                                    }} color="primary">
                                        Add Advert Platform
                                    </Button>
                                </div>


                                <br />
                                <ReactDatatable
                                    config={config}
                                    records={farmer}
                                    columns={columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={isLoading}
                                    total_record={datatotal}
                                    onChange={tableChangeHandler} />
                            </div>
                        </Table>
                    </CardBody>


                </Card>

            </>
        </div>
    )

}
export default Advertisers;