import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../../_assets/css/file.css"
import { Card, CardBody, Table } from 'reactstrap';
import { baseURL, CONFIG } from '../../configs/exports';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import * as moment from 'moment';
import ReactDatatable from '@ashvin27/react-datatable';


//farmer hooks

const WithdrawRequests = () => {

    const [farmer, setFarmer] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [datatotal, setDataTotal] = useState()
    const [queryString, setQueryString] = useState("")

    useEffect(() => {
        setLoad(true)

        let url = baseURL + `withdrawals?` + queryString;
        // alert(url)
        axios.all([
            axios.get(url, CONFIG)
        ]).then(axios.spread((farmerResponse) => {
            let data = [];
            if (farmerResponse.data.data.length > 0) {
                setDataTotal(farmerResponse.data.data.length)
                setLoad(false)
                for (let i = 0; i < farmerResponse.data.data.length; i++) {
                    let date = { dates: (moment.utc(farmerResponse.data.data[i].created_on).format('DD/MM/YYYY HH:mm:ss')) };
                    data.push(Object.assign(date, farmerResponse.data.data[i]));
                    setLoad(false)
                }
                setDataTotal(farmerResponse.data.total)
                setFarmer(data)
            } else {
                setLoad(false)
                setFarmer(data)
            }
        }))

    }, [queryString]);


    const config = {
        key_column: "tsc",
        length_menu: [20, 100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 20,
        button: {
            csv: true,
            excel: true
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search here..",
            no_data_text: "There was no record found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }


    const columns = [
        {
            key: "name",
            TrOnlyClassName: 'cell',
            text: "Name",
            className: "tsc",
            align: "left"
        },

        {
            key: "msisdn",
            TrOnlyClassName: 'cell',
            text: "Phone#",
            className: "tsc",
            align: "left"
        },

        {
            key: "transaction_id",
            TrOnlyClassName: 'cell',
            text: "Transaction ID",
            className: "tsc",
            align: "left"
        },
        // {
        //     key: "action",
        //     text: "Status",
        //     TrOnlyClassName: 'cell',
        //     className: "cell",
        //     sortable: false,
        //     cell: record => {
        //         return (
        //             <Fragment className="center" >

        //                 {record.status === 'success' ?
        //                     <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
        //                         success
        //                     </span>
        //                     :
        //                     record.status === 'pending' ?
        //                         <span class="badge-warning" style={{ borderRadius: "5px", padding: "2px" }}>
        //                             pending
        //                         </span>

        //                         :
        //                         <>
        //                             {
        //                                 record.status === 'failed' ?
        //                                     <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
        //                                         failed
        //                                     </span>
        //                                     : null
        //                             }
        //                         </>
        //                 }
        //             </Fragment >
        //         );
        //     }
        // },

        {
            key: "reference",
            TrOnlyClassName: 'cell',
            text: "Ref#",
            className: "tsc",
            align: "left"
        },

        // {
        //     key: "action",
        //     text: "Loan Status",
        //     TrOnlyClassName: 'cell',
        //    className: "tsc",
        //     width: 250,
        //     sortable: false,
        //     cell: record => {
        //         return (
        //             <Fragment className="center" >

        //                 {record.status === 0 ?
        //                     <div>
        //                         <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
        //                             Pending
        //                         </span>
        //                     </div>
        //                     : null}
        //                 {record.status === 1 ?
        //                     <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
        //                         Success
        //                     </span>
        //                     : null
        //                 }
        //             </Fragment >
        //         );
        //     }
        // },
        {
            key: "amount",
            TrOnlyClassName: 'cell', text: "Amount",
            className: "tsc",
            align: "left"
        },
        {
            key: "dates",
            TrOnlyClassName: 'cell', text: "Date",
            className: "tsc",
            align: "left"
        },
    ];

    // row clickerd
    // const rowClickedHandler = (data) => {
    //     setFarmerProps(data);
    //     setFarmerDetails(true);
    // };

    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');
        setQueryString(queryString)
    }



    return (
        <div style={{ marginTop: "-20px" }} >
            < >
                < Card >
                    <CardBody >
                        <Table responsive hover>
                            <div className="panel-body" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4> <b>Withdrawal Requests</b></h4>
                                    </div>
                                    <div className="col-6">
                                        <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}
                                            onClick={
                                                () => {
                                                    setQueryString(require("randomstring").generate({
                                                        length: 1,
                                                        charset: 'alphanumeric',
                                                        capitalization: 'lowercase'
                                                    }))
                                                }} >
                                            <RefreshIcon />
                                        </button>
                                    </div>
                                </div>
                                <br />
                                <div >
                                    Total Requests: <b>{datatotal}</b>
                                    <br /><br />
                                </div>
                                <ReactDatatable
                                    config={config}
                                    records={farmer}
                                    columns={columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={isLoading}
                                    total_record={datatotal}
                                    onChange={tableChangeHandler} />
                            </div>
                        </Table>
                    </CardBody>


                </Card>
            </>
        </div>
    )

}
export default WithdrawRequests;
