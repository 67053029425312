import React, { useState, useEffect, Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { Button, Card, CardBody, Table } from 'reactstrap';
import { baseURL, CONFIG, formatCurrency, useStyles } from '../../configs/exports';
import * as moment from 'moment';
import { Autorenew } from "@material-ui/icons";
import clsx from "clsx";
import { Link } from 'react-router-dom';


//farmer hooks

const CustomerStatement = (state) => {

    const [farmer, setFarmer] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [datatotal, setDataTotal] = useState()
    const [queryString, setQueryString] = useState("")

    // alert(state.location.state.farmer_id)
    const [spin, setSpin] = useState(false);
    const refreshCanvas = () => {
        setSpin(true);
        setTimeout(() => {
            setSpin(false);
        }, 1000);
    };
    const classes = useStyles();


    useEffect(() => {
        setLoad(true)

        let url = baseURL + `customer_statement?user_id=${state.location.state.farmer.id}&${queryString}`;
        // alert(JSON.stringify(state.location.state.farmer))
        axios.all([
            axios.get(url, CONFIG)
        ]).then(axios.spread((farmerResponse) => {
            let data = [];
            if (farmerResponse.data.data.length > 0) {

                /**
               * @description If list is
               */
                let params = new URLSearchParams(queryString)
                let id = parseInt(params.get("page_number"))

                let p = 0;
                if (id > 1) {
                    p = (((id - 1) * parseInt(params.get("page_size"))))
                }

                setDataTotal(farmerResponse.data.data.length)
                setLoad(false)
                for (let i = 0; i < farmerResponse.data.data.length; i++) {
                    p++;
                    let unrow = { row: p }
                    let mdebit;
                    let mcredit;

                    if (farmerResponse.data.data[i].debit === '-') {
                        mdebit = { m_debit: '-' };
                    } else {
                        mdebit = { m_debit: formatCurrency(farmerResponse.data.data[i].debit) };
                    }

                    if (farmerResponse.data.data[i].credit === '-') {
                        mcredit = { m_credit: '-' };
                    } else {
                        mcredit = { m_credit: formatCurrency(farmerResponse.data.data[i].credit) };
                    }

                    let m_balance = { m_balance: formatCurrency(farmerResponse.data.data[i].balance) };

                    let date = { dates: (moment.utc(farmerResponse.data.data[i].created).format('DD/MM/YYYY HH:mm:ss')) };
                    data.push(Object.assign(date, unrow, m_balance, mdebit, mcredit, farmerResponse.data.data[i]));
                    setLoad(false)
                }
                setDataTotal(farmerResponse.data.total)
                setFarmer(data)
            } else {
                setLoad(false)
                setFarmer(data)
            }
        }))

    }, [queryString]);// eslint-disable-line react-hooks/exhaustive-deps

    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        button: {
            csv: true
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search..",
            no_data_text: "There was no record found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }

    const columns = [
        {
            key: "row",
            TrOnlyClassName: 'cell',
            text: "#",
            className: "tsc",
            align: "left"
        },


        {
            key: "dates",
            TrOnlyClassName: 'cell', text: "Date",
            className: "tsc",
            align: "left"
        },

        {
            key: "narrative",
            TrOnlyClassName: 'cell',
            text: "Description",
            className: "tsc",
            align: "left"
        },

        {
            key: "m_debit",
            TrOnlyClassName: 'cell',
            text: "Debit",
            className: "tsc",
            align: "left"
        },

        {
            key: "m_credit",
            TrOnlyClassName: 'cell',
            text: "Credit",
            className: "tsc",
            align: "left"
        },
        {
            key: "m_balance",
            TrOnlyClassName: 'cell',
            text: "Balance",
            className: "tsc",
            align: "left"
        },



    ];



    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');
        setQueryString(queryString)
    }



    return (
        <div style={{ marginTop: "-20px" }} >
            < >
                < Card >
                    <CardBody >
                        <Table responsive hover>
                            <div className="panel-body" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4> <b>Customer Statement</b> {state.location.state.farmer.username}</h4>
                                    </div>
                                    <div className="col-6">
                                        <Link
                                            to={{
                                                pathname: '/customer_details',
                                                state: {
                                                    farmer: state.location.state.farmer
                                                }
                                            }}>
                                            <Button className="pull-right btn-sm" outline color="primary">
                                                Swtich back
                                            </Button>
                                        </Link>
                                    </div>
                                </div>

                                <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}

                                    onClick={
                                        () => {
                                            refreshCanvas()
                                            setQueryString(require("randomstring").generate({
                                                length: 1,
                                                charset: 'alphanumeric',
                                                capitalization: 'lowercase'
                                            }))
                                        }}
                                >
                                    Refresh
                                    <Autorenew
                                        className={clsx({
                                            [classes.refresh]: true,
                                            spin: spin
                                        })}
                                        onClick={
                                            () => {
                                                refreshCanvas()
                                                setQueryString(require("randomstring").generate({
                                                    length: 1,
                                                    charset: 'alphanumeric',
                                                    capitalization: 'lowercase'
                                                }))
                                            }}
                                        spin={360}
                                    />

                                </button>

                                <br />
                                <ReactDatatable
                                    config={config}
                                    records={farmer}
                                    columns={columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={isLoading}
                                    total_record={datatotal}
                                    onChange={tableChangeHandler} />
                            </div>
                        </Table>
                    </CardBody>


                </Card>

            </>
        </div>
    )

}
export default CustomerStatement;