import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { USER } from '../../../configs/exports';

const SidebarContent = ({
  onClick, changeToLight, changeToDark, sidebarCollapse,
}) => {
  const hideSidebar = () => {
    onClick();
  };

  return (
    <div className="sidebar__content">

      {USER.role_id == 2 ?
        <>
          <SidebarLink
            title="Search Customer"
            icon="user"
            route="/find_customer"
            onClick={hideSidebar}
          />

          <SidebarLink
            title="Customers"
            icon="users"
            route="/customers"
            onClick={hideSidebar}
          />

          <SidebarLink
            title="Advert Platforms"
            icon="bullhorn"
            route="/adversiters"
            onClick={hideSidebar}
          />


          <SidebarCategory title="Report" icon="briefcase">

            <SidebarLink
              title="New Customer Recruitment"
              route="/recruited"
              onClick={hideSidebar}
            />
            <SidebarLink
              title="Deposit"
              route="/customer_deposit_all"
              onClick={hideSidebar}
            />






            <SidebarLink
              title="Daily Deposits "
              route="/dailydeposit"
              onClick={hideSidebar}
            />
            <SidebarLink
              title="Deposit Per Hour"
              route="/depositperhour"
              onClick={hideSidebar}
            />
            <SidebarLink
              title="Withdrawals"
              route="/withdrawal"
              onClick={hideSidebar}
            />

          </SidebarCategory>

          <SidebarLink
            title="Send Bulk"
            icon="users"
            route="/send_bulk"
            onClick={hideSidebar}
          />

        </>
        :
        <ul className="sidebar__block">
          <>
            <SidebarLink
              title="Search Customer"
              icon="user"
              route="/find_customer"
              onClick={hideSidebar}
            />

            <SidebarLink
              title="Customers"
              icon="users"
              route="/customers"
              onClick={hideSidebar}
            />
            {/* <SidebarLink
              title="BlackList"
              icon="users"
              route="/customers"
              onClick={hideSidebar}
            /> */}
            <SidebarLink
              title="BlackList"
              icon="briefcase"
              route="/blacklist"
              onClick={hideSidebar}
            />


          </>

        </ul>



      }
      <ul className="sidebar__block">
        <SidebarLink title="Log Out" icon="exit" route="/login" />
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
