/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Form } from 'react-bootstrap';
import { Button } from 'reactstrap';
import { MDBCloseIcon } from "mdbreact"
import { baseURL_PORTAL, CONFIG, errorToast, successToast, ToastTable } from '../../../configs/exports';
import axios from 'axios';
import CardIcon from 'mdi-react/CardIcon';
import './style2.css';

const LogIn = () => {

  // initialing hooks
  const [showPassword, setShowPassword] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [msisdn, setMsisdn] = React.useState('');
  const [password, setPassword] = React.useState(null);
  const [openPassword, isOpenPassword] = React.useState(false);
  const [loading, isLoading] = React.useState('');
  const [loggedin, isLoggedIn] = React.useState(localStorage.getItem("isLoggedIn") ? true : false);

  useEffect(() => {
    // check if user was logged in
    if (loggedin) {
      // window.location.href = "find_customer";
    }
  }, [loggedin]);

  const handleChangeMsisdn = event => {
    setMsisdn(event.target.value);
  };

  const handleChangeUsername = event => {
    setUsername(event.target.value);
  };

  const handleChangePassword = event => {
    setPassword(event.target.value);
  };

  const showPasswordToggle = () => {
    // eslint-disable-next-line
    if (showPassword == true) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };


  //submit function
  const onSubmit = e => {
    e.preventDefault();
    isLoading(true);
    if (username === "") {
      errorToast("Username is required")
    } else if (password === "") {
      errorToast("Password is required")
    }
    else {
      let formData = {
        "username": username,
        "password": password,
      }
      let url = baseURL_PORTAL + "login";
      // calling login files
      axios.post(url, formData, CONFIG).then((response) => {
        if (response.data.success) {
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("user", JSON.stringify(response.data.message));
          localStorage.setItem("token", response.data.token);
          isLoggedIn(true);
          isLoading(false);
          successToast("Login successful. Redirecting..");
          window.setTimeout(() => {
            window.location.href = "find_customer";
          }, 10);


        } else {
          errorToast(response.data.message);

        }

      }).catch(error => {

        isLoading(false);


        errorToast(error.response.data.message)

      })



    }
  };

  const closeModalReset = e => {
    isOpenPassword(false);
  };

  // const resetpasswordchange = e => {
  //   isOpenPassword(true);
  // };


  // reset password functions
  const onSubmitPasswordReset = e => {
    e.preventDefault();
    // isLoading(true);
    setMsisdn(e.msisdn)
    console.log(msisdn)
    let formData = {
      "msisdn": msisdn,
    }
    axios.post(`${baseURL_PORTAL}authentication/password-reset`, formData, CONFIG).then((res) => {
      if (res.data.Success) {
        successToast("Password reset successful. Check your phone")
        // eslint-disable-next-line
        window.setTimeout(() => {
          // alert(response.data.user.role)
          isOpenPassword(false);
          isLoading(false)
        }, 3000);
      }
      else {

        errorToast(res.data.message)
        window.setTimeout(() => {
          // alert(response.data.user.role)
          isLoading(false)
        }, 5000);
      }
    }).catch(error => {

      errorToast(error.data.message)
      window.setTimeout(() => {
        // alert(response.data.user.role)
        isLoading(false)
      }, 5000);
    });
  }


  // return the UI
  return (
    <div className="elite-login">

      {ToastTable()}

      <Modal
        isOpen={openPassword}
        onRequestClose={e => {
          closeModalReset(e);
        }}
        contentLabel="My dialog"
        className="mymodal"
        onAfterOpen={() => {
          document.body.style.overflow = 'hidden';
        }}
        onAfterClose={() => {
          document.body.removeAttribute('style');
        }}
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <MDBCloseIcon onClick={closeModalReset} />
        <h4><b>Reset Password</b></h4>
        <>
          <Form className="form login-form" onSubmit={onSubmitPasswordReset}>
            {/*n  <h5><b>Get Agent Number</b></h5> */}
            <div className="form__form-group">
              <br></br>
              <span className="form__form-group-label">Phone number</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <CardIcon />
                </div>
                <Form.Control
                  autoFocus
                  type="text"
                  name="msisdn"
                  style={{ color: "black", borderColor: "hsl(0,0%,80%)" }}
                  placeholder="Enter your phone number starting with 254"
                  className="input-without-border-radius"
                  value={msisdn}
                  onChange={handleChangeMsisdn}
                />
              </div>
              <br />
            </div>
            <div className="account__btns col-8 offset-2">
              <Button className="account__btn" type='submit' color="success"> {
                loading ? "Please wait..." : "Proceed"
              }</Button>
            </div>

          </Form>
        </>
      </Modal>





      <div className="elite-login-sec">
        <div className="row">
          <div className="col-md-6 elite-login-left">
            <div className="carousel-wrap">
              <div className="item">
                <div className="item-sec">
                  {/* <div className="login_slider_image"><img src={img} alt="logo" /></div> */}
                  <p className="item-text" style={{ color: "white" }}></p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 elite-login-right">
            <h1 className="elite-login-head">SUPERSORTE</h1>
            <form className="elite-login-form" onSubmit={onSubmit} >

              <div className="elite-form-field">
                <label htmlFor="user_email">Your username</label>
                <input className="form-control"
                  placeholder="Enter Username"
                  id="elite-username"
                  required="required"
                  type="text"
                  onChange={handleChangeUsername}
                  style={{ borderColor: "grey" }}
                  value={username}
                  name="username" />
              </div>



              <div className="elite-form-field">
                <label htmlFor="user_email">Password</label>
                <input
                  placeholder="Enter your password"
                  id="elite-email"
                  className="form-control"
                  required="required"
                  style={{ borderColor: "grey" }}
                  value={password}
                  onChange={handleChangePassword}
                  type={showPassword === true ? "text" : "password"}
                />
                <input type="hidden" name="user_timezone" id="user_timezone" />
                <span toggle="#password-field" onClick={showPasswordToggle} className="fa fa-fw fa-eye field-icon toggle-password" />

                {/* <p className="elite-agent-pwd" >
                  <a href="hhtp" onClick={resetpasswordchange}
                    data-toggle="modal" data-target="#specialist-forgotModal">
                    Forgot Password?
                  </a></p> */}


                <br /><br />

                <br /><br />
                <br /><br />

              </div>

              <button type="submit" className="elite-form-btn"> {
                loading ? "Please wait..." : "Sign In"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(state => ({ theme: state.theme }))(LogIn);
