import React, { useState, Fragment, useEffect } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';

import axios from 'axios';
import "../../_assets/css/file.css"

import { Card, CardBody, Table, Nav, NavItem, NavLink } from 'reactstrap';

import { baseURL, CONFIG, formatCurrency, useStyles } from '../../configs/exports';
import * as moment from 'moment';
// import loanDetails from './loanDetails';
import { Redirect } from 'react-router-dom';
import classnames from 'classnames';
import { Autorenew } from "@material-ui/icons";
import clsx from "clsx";


//loan hooks

const Loan = () => {
    const [loan, setloan] = useState([])
    const [loanProps, setloanProps] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [datatotal, setDataTotal] = useState()
    const [queryString, setQueryString] = useState("")
    const [loanDetails, setloanDetails] = useState(false)
    const [activeTab, setActiveTab] = useState("1")
    const [spin, setSpin] = useState(false);
    const refreshCanvas = () => {
        setSpin(true);
        setTimeout(() => {
            setSpin(false);
        }, 1000);
    };
    const [total_loans, setTotalLoans] = useState(0)

    const [loan_issued, setTotalIssued] = useState(0)

    const classes = useStyles();



    useEffect(() => {
        setLoad(true)

        let url;
        url = baseURL + `loan?` + queryString;

        axios.all([
            axios.get(url, CONFIG)
        ]).then(axios.spread((loanResponse) => {


            let mloan = loanResponse.data.data.filter(item => item.status === parseInt(activeTab))

            let data = [];
            if (mloan.length > 0) {
                setDataTotal(mloan.length)
                setLoad(false)
                let valueExpected = 0
                let countExpected = 0

                let valueCount = 0
                let countCount = 0


                for (let i = 0; i < mloan.length; i++) {

                    let mname, memail, mcountry, magent, mbranch;
                    countCount = 1
                    valueCount += countCount //count loans issued


                    countExpected = (mloan[i].total_amount)
                    valueExpected += countExpected //expected

                    if (mloan[i].agent_name == null) {
                        magent = { magent: "_" };
                    } else {
                        magent = { magent: mloan[i].agent_name };
                    }

                    if (mloan[i].branch_name == null) {
                        mbranch = { mbranch: "_" };
                    } else {
                        mbranch = { mbranch: mloan[i].branch_name };
                    }


                    if (mloan[i].country == null) {
                        mcountry = { mcountry: "_" };
                    } else {
                        mcountry = { mcountry: mloan[i].country };
                    }

                    if (mloan[i].name == null) {
                        mname = { mname: "_" };
                    } else {
                        mname = { mname: mloan[i].name };
                    }


                    if (mloan[i].email == null) {
                        memail = { memail: "_" };
                    } else {
                        memail = { memail: mloan[i].email };
                    }

                    let f_amount = { f_amount: formatCurrency(parseFloat(mloan[i].total_amount).toFixed(2)) };
                    let f_amount_interest = { f_amount_interest: formatCurrency(mloan[i].interest) };

                    let f_amount_limit = { f_amount_limit: formatCurrency(parseFloat(mloan[i].loan_limit).toFixed(2)) };
                    let enddate = { enddates: (moment.utc(mloan[i].end_date).format('DD/MM/YYYY')) };

                    let date = { dates: (moment.utc(mloan[i].created_on).format('DD/MM/YYYY')) };


                    let days = { days: mloan[i].day };

                    data.push(Object.assign(date, days, enddate, f_amount_interest,
                        f_amount_limit, mbranch, mcountry, magent, mname, memail, f_amount, mloan[i]));
                    setLoad(false)
                    setTotalLoans(valueCount)
                    setTotalIssued(valueExpected)

                }

                setDataTotal(loanResponse.data.total)
                setloan(data)
            } else {
                setTotalLoans(0)
                setTotalIssued(0)
                setLoad(false)
                setloan(data)
            }
        }))

    }, [activeTab, queryString]);


    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        button: {
            csv: true
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search..",
            no_data_text: "There was no record found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }
    const columns = [
        {
            key: "mname",
            TrOnlyClassName: 'cell',
            text: "Name",
            className: "tsc",
            align: "left"
        },

        {
            key: "msisdn",
            TrOnlyClassName: 'cell',
            text: "Phone#",
            className: "tsc",
            align: "left"
        },


        {
            key: "location",
            TrOnlyClassName: 'cell',
            text: "Location",
            className: "tsc",
            align: "left"
        },
        {
            key: "f_amount_limit",
            TrOnlyClassName: 'cell',
            text: "Limit",
            className: "tsc",
            align: "left"
        },
        {
            key: "f_amount",
            TrOnlyClassName: 'cell',
            text: "Amount Issued",
            className: "tsc",
            align: "left"
        },
       
        {
            key: "action",
            text: "Days",
            TrOnlyClassName: 'cell',
            className: "cell",
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >

                        {record.day < 31 ?
                            <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                {record.day} days Old
                            </span>
                            :
                            // eslint-disable-next-line
                            record.day > 30 && (record.status == 1 || record.status == 2) ?
                                <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                    Overdue by {record.day - 30}
                                </span>
                                : null}
                    </Fragment >
                );
            }
        },
        {
            key: "action",
            text: "Status",
            TrOnlyClassName: 'cell',
            className: "cell",
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >

                        {record.status === 1 ?
                            <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                Applied
                            </span>
                            :
                            record.status === 2 ?
                                <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                    Paid
                                </span>

                                :
                                <>
                                    {
                                        record.status === 3 ?
                                            <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                                Defauled
                                            </span>
                                            : null
                                    }
                                </>
                        }
                    </Fragment >
                );
            }
        },

        {
            key: "enddates",
            TrOnlyClassName: 'tsc',
            text: "End Date",
            className: "cell",
            align: "left"
        },
        {
            key: "agent_name",
            TrOnlyClassName: 'cell',
            text: "Agent",
            className: "tsc",
            align: "left"
        },

        {
            key: "branch_name",
            TrOnlyClassName: 'cell',
            text: "Branch",
            className: "tsc",
            align: "left"
        },

        {
            key: "country_name",
            TrOnlyClassName: 'cell',
            text: "Country",
            className: "tsc",
            align: "left"
        },



        {
            key: "dates",
            TrOnlyClassName: 'tsc',
            text: "Date Created",
            className: "cell",
            align: "left"
        },

        {
            key: "action",
            text: "Options",
            TrOnlyClassName: 'cell',
            className: "cell",
            width: 250,
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >

                        <button className="btn btn-primary btn-sm"
                            title="View pics Details"
                            style={
                                { marginRight: '10px' }}
                            onClick={() => { rowClickedHandler(record) }} >
                            Statement
                        </button>

                    </Fragment>
                );
            }
        }
    ];


    // row clickerd
    const rowClickedHandler = (data) => {
        setloanProps(data);
        setloanDetails(true);
    };

    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');
        setQueryString(queryString)
    }

    const toggle = (activeTab) => {

        setActiveTab(activeTab)

    }


    // return UI
    return (
        <div style={{ marginTop: "-20px" }} >
            < >
                {loanDetails === true ?
                    <>
                        <Redirect
                            to={{
                                pathname: '/customer_statement',
                                state: {
                                    reference: {
                                        reference: loanProps.reference,
                                        id: loanProps.customer_id,
                                        name: loanProps.name,
                                        id_no: loanProps.id_no
                                    }

                                }
                            }}
                        />
                    </> :
                    null}
                < Card >
                    <CardBody >
                        <h4><b>Loans</b></h4><br />
                        <Table responsive hover>
                            <div className="panel-body" >

                                <Nav tabs>

                                    <NavItem className='tablayout'>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => {
                                                toggle('1');
                                            }}
                                        >
                                            Active
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className='tablayout'>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => {
                                                toggle('2');
                                            }}
                                        >
                                            Paid
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className='tablayout'>
                                        <NavLink
                                            className={classnames({ active: activeTab === '3' })}
                                            onClick={() => {
                                                toggle('3');
                                            }}
                                        >
                                            Defaulted
                                        </NavLink>
                                    </NavItem>

                                </Nav>
                                <div className="row">
                                    <div className="col-md-6">
                                        <br />
                                        <h4>Total Loan Issued: <b>{formatCurrency(loan_issued)}</b></h4>
                                        <h4>Loan Count: <b>{total_loans}</b></h4>

                                    </div>
                                    <div className="col-6">
                                        <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}

                                            onClick={
                                                () => {
                                                    refreshCanvas()
                                                    setQueryString(require("randomstring").generate({
                                                        length: 1,
                                                        charset: 'alphanumeric',
                                                        capitalization: 'lowercase'
                                                    }))
                                                }}
                                        >
                                            Refresh
                                            <Autorenew
                                                className={clsx({
                                                    [classes.refresh]: true,
                                                    spin: spin
                                                })}
                                                onClick={
                                                    () => {
                                                        refreshCanvas()
                                                        setQueryString(require("randomstring").generate({
                                                            length: 1,
                                                            charset: 'alphanumeric',
                                                            capitalization: 'lowercase'
                                                        }))
                                                    }}
                                                spin={360}
                                            />

                                        </button>
                                    </div>
                                </div>
                                <br />
                                <ReactDatatable
                                    config={config}
                                    records={loan}
                                    columns={columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={isLoading}
                                    total_record={datatotal}
                                    onChange={tableChangeHandler} />
                            </div>
                        </Table>
                    </CardBody>


                </Card>

            </>
        </div>
    )

}
export default Loan;
