import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../Layout/index';
import Default from '../../../Dashboards/Default';
import { UserSearch } from '../../../Customers/usersearch';
import UsersStaffs from '../../../Users/UsersStaffs';

import Customers from '../../../Customers/Customer';
import CustomerDetails from '../../../Customers/CustomerDetails';
import ImageView from '../../../../shared/components/catalog/imageView';
import SMSLogs from '../../../SMSLogs/SMSlogs';
import WithdrawRequests from '../../../Reports/WithdrawRequests';
import Loan from '../../../Loans/Loan';
import CustomerStatement from '../../../Customers/CustomerStatement';
import Countries from '../../../Countries/Countries';
import Branches from '../../../Branches/Branches';
import Roles from '../../../Roles/Roles';

import SuspendAccount from '../../../Reports/SuspendAccount';
import DepositArchive from '../../../Reports/Deposits';
import CustomerDeposit from '../../../Customers/CustomerDeposit';
import CustomerWithdrawal from '../../../Customers/CustomerWithdrawal';
import CustomerDepositAll from '../../../Customers/CustomerDepositAll';
import { sendSMS } from '../../../Customers/sendSMS';
import Advertisers from '../../../Customers/Advertisers';
import { DailyDeposit } from '../../../Finance/dailydeposits';
import { DepositPerHour } from '../../../Finance/depositPerHour';
import { WithdrawalSummary } from '../../../Finance/withdawals';
import { CustomerRecruited } from '../../../Finance/customer_recruited';



export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/staffs" component={UsersStaffs} />
      <Route path="/find_customer" component={UserSearch} />
      <Route path="/dashboard" component={Default} />
      <Route path="/customers" component={Customers} />
      <Route path="/customer_details" component={CustomerDetails} />
      <Route path="/photo_view" component={ImageView} />
      <Route path="/messages" component={SMSLogs} />
      <Route path="/withdrawals" component={WithdrawRequests} />
     
      <Route path="/dailydeposit" component={DailyDeposit} />

      <Route path="/depositperhour" component={DepositPerHour} />

      <Route path="/withdrawal" component={WithdrawalSummary} />
      <Route path="/recruited" component={CustomerRecruited} />

      

      <Route path="/loans" component={Loan} />
      {/* <Route path="/customer_statement" component={CuastomerStatement} /> */}



      <Route path="/countries" component={Countries} />
      <Route path="/roles" component={Roles} />
      <Route path="/branches" component={Branches} />

      <Route path="/suspend_account" component={SuspendAccount} />
      <Route path="/deposits" component={DepositArchive} />
      <Route path="/customer_deposits" component={CustomerDeposit} />

      <Route path="/customer_withdrawal" component={CustomerWithdrawal} />

      <Route path="/customer_statement" component={CustomerStatement} />

      <Route path="/customer_deposit_all" component={CustomerDepositAll}/>

      <Route path="/send_bulk" component={sendSMS}/>

      <Route path="/adversiters" component={Advertisers} />

      {/* <Route path="/send_sms" component={SMSLogs}/> */}


    </div>
  </div>
);
