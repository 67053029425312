import React, { useState, Fragment, useEffect } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';

import axios from 'axios';
import "../../_assets/css/file.css"

import { Card, CardBody, Table, Nav, NavItem, NavLink } from 'reactstrap';

import { baseURL, CONFIG, formatCurrency, useStyles } from '../../configs/exports';
import * as moment from 'moment';
// import FarmerDetails from './FarmerDetails';
import { Redirect } from 'react-router-dom';
import classnames from 'classnames';
import { Autorenew } from "@material-ui/icons";
import clsx from "clsx";


//farmer hooks

const Customers = () => {

    const [farmer, setFarmer] = useState([])
    const [farmerProps, setFarmerProps] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [datatotal, setDataTotal] = useState()
    const [queryString, setQueryString] = useState("")
    const [farmerDetails, setFarmerDetails] = useState(false)
    const [activeTab, setActiveTab] = useState("1")
    const [spin, setSpin] = useState(false);
    const refreshCanvas = () => {
        setSpin(true);
        setTimeout(() => {
            setSpin(false);
        }, 1000);
    };
    const classes = useStyles();


    useEffect(() => {
        setLoad(true)

        let url;
        if (activeTab === "1") {
            url = baseURL + `customer?` + queryString;
        } else if (activeTab === "2") {
            url = baseURL + `to_upload_document?` + queryString;
        } else {
            url = baseURL + `customer_loans?` + queryString;
        }
        // alert(url)
        axios.all([
            axios.get(url, CONFIG)
        ]).then(axios.spread((customerResponse) => {
            let data = [];
            if (customerResponse.data.data.length > 0) {
                setDataTotal(customerResponse.data.data.length)
                setLoad(false)
                for (let i = 0; i < customerResponse.data.data.length; i++) {

                    let mname, memail, mcountry, magent, mbranch;


                    if (customerResponse.data.data[i].agent_name == null) {
                        magent = { magent: "_" };
                    } else {
                        magent = { magent: customerResponse.data.data[i].agent_name };
                    }

                    if (customerResponse.data.data[i].branch_name == null) {
                        mbranch = { mbranch: "_" };
                    } else {
                        mbranch = { mbranch: customerResponse.data.data[i].branch_name };
                    }


                    if (customerResponse.data.data[i].country == null) {
                        mcountry = { mcountry: "_" };
                    } else {
                        mcountry = { mcountry: customerResponse.data.data[i].country };
                    }

                    if (customerResponse.data.data[i].name == null) {
                        mname = { mname: "_" };
                    } else {
                        mname = { mname: customerResponse.data.data[i].name };
                    }


                    if (customerResponse.data.data[i].email == null) {
                        memail = { memail: "_" };
                    } else {
                        memail = { memail: customerResponse.data.data[i].email };
                    }

                    let f_amount = { f_amount: formatCurrency(parseFloat(customerResponse.data.data[i].balance_satoshis)) };

                    let date = { dates: (moment.utc(customerResponse.data.data[i].created).format('DD/MM/YYYY')) };
                    data.push(Object.assign(date, mbranch, mcountry, magent, mname, memail, f_amount, customerResponse.data.data[i]));
                    setLoad(false)
                }
                setDataTotal(customerResponse.data.total)
                setFarmer(data)
            } else {
                setLoad(false)
                setFarmer(data)
            }
        }))

    }, [activeTab, queryString]);


    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        button: {
            csv: true
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search..",
            no_data_text: "There was no record found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }
    const columns = [
        {
            key: "username",
            TrOnlyClassName: 'cell',
            text: "Username",
            className: "tsc",
            align: "left"
        },

        {
            key: "msisdn",
            TrOnlyClassName: 'cell',
            text: "Phone#",
            className: "tsc",
            align: "left"
        },
        {
            key: "memail",
            TrOnlyClassName: 'cell',
            text: "Email",
            className: "tsc",
            align: "left"
        },
     
        {
            key: "f_amount",
            TrOnlyClassName: 'cell',
            text: "Balance#",
            className: "tsc",
            align: "left"
        },
        {
            key: "dates",
            TrOnlyClassName: 'cell',
            text: "Date",
            className: "tsc",
            align: "left"
        },
        {
            key: "action",
            text: "Options",
            TrOnlyClassName: 'cell',
            with: 100,
            className: "tsc",
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >
                        {/* <Link to="/farmer_details" > */}

                        <button className="btn btn-success btn-sm"
                            title="View More"
                            style={
                                { marginRight: '10px' }}
                            onClick={() => { rowClickedHandler(record) }} >

                            More <i className="fa fa-plus"></i>
                        </button>

                        {/* </Link> */}
                    </Fragment>
                )
            }
        }
    ];

    // row clickerd
    const rowClickedHandler = (data) => {
        setFarmerProps(data);
        setFarmerDetails(true);
    };

    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');
        setQueryString(queryString)
    }

    const toggle = (activeTab) => {

        setActiveTab(activeTab)

    }


    // return UI
    return (
        <div style={{ marginTop: "-20px" }} >
            < >
                {farmerDetails === true ?
                    <>
                        <Redirect
                            to={{
                                pathname: '/customer_details',
                                state: {
                                    farmer: farmerProps
                                }
                            }}
                        />
                    </> :
                    null}
                < Card >
                    <CardBody >
                        <Table responsive hover>
                            <div className="panel-body" >

                                <Nav tabs>

                                    <NavItem className='tablayout'>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => {
                                                toggle('1');
                                            }}
                                        >
                                            All Customers
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem className='tablayout'>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => {
                                                toggle('2');
                                            }}
                                        >
                                            Document not Submitted
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className='tablayout'>
                                        <NavLink
                                            className={classnames({ active: activeTab === '3' })}
                                            onClick={() => {
                                                toggle('3');
                                            }}
                                        >
                                            Document Submitted
                                        </NavLink>
                                    </NavItem> */}

                                </Nav>
                                <div className="row">
                                    <div className="col-md-6">
                                    </div>
                                    <div className="col-6">
                                        <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}

                                            onClick={
                                                () => {
                                                    refreshCanvas()
                                                    setQueryString(require("randomstring").generate({
                                                        length: 1,
                                                        charset: 'alphanumeric',
                                                        capitalization: 'lowercase'
                                                    }))
                                                }}
                                        >
                                            Refresh
                                            <Autorenew
                                                className={clsx({
                                                    [classes.refresh]: true,
                                                    spin: spin
                                                })}
                                                onClick={
                                                    () => {
                                                        refreshCanvas()
                                                        setQueryString(require("randomstring").generate({
                                                            length: 1,
                                                            charset: 'alphanumeric',
                                                            capitalization: 'lowercase'
                                                        }))
                                                    }}
                                                spin={360}
                                            />

                                        </button>
                                    </div>
                                </div>
                                <ReactDatatable
                                    config={config}
                                    records={farmer}
                                    columns={columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={isLoading}
                                    total_record={datatotal}
                                    onChange={tableChangeHandler} />
                            </div>
                        </Table>
                    </CardBody>


                </Card>

            </>
        </div>
    )

}
export default Customers;
